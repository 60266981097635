import { Injectable, NgZone, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';

import { PanoramaHotspotClickEvent } from '@yuno/api/interface';

import { EventTypesArray, KrpanoEvents, KrpanoInstance, View } from '../types';

@Injectable({
	providedIn: 'root'
})
export class KrpanoEventsService {
	private zone = inject(NgZone);

	/**
	 * Stores the reference to the KRPANO instance used by the eventHooks
	 */
	krpanoInstance: KrpanoInstance;

	private _clickEvents = new Subject<PanoramaHotspotClickEvent>();
	clickEvents$ = this._clickEvents.asObservable();
	$clickEvents = toSignal(this.clickEvents$);

	setClickEvent(event: PanoramaHotspotClickEvent): void {
		this._clickEvents.next(event);
	}

	hookEvents(events: KrpanoEvents, krpano: KrpanoInstance) {
		this.krpanoInstance = krpano;

		for (const type of EventTypesArray) {
			if (events[type].observed) {
				switch (type) {
					case 'onidle':
						// this event is enabled in the main component
						break;
					case 'onviewchange':
					case 'onviewchanged':
						this.krpanoInstance.events[type] = () => {
							this.zone.run(() => {
								events[type].emit(this.getView());
							});
						};
						break;
					default:
						this.krpanoInstance.events[type] = () => {
							this.zone.run(() => {
								events[type].emit();
							});
						};
						break;
				}
			}
		}
	}

	getView(): View {
		return {
			vlookat: this.krpanoInstance?.view?.vlookat || 0,
			hlookat: this.krpanoInstance?.view?.hlookat || 0,
			fov: this.krpanoInstance?.view?.fov || 0
		};
	}
}
